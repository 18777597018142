<template>
    <v-card class="mx-auto"
            max-width="500"
            outlined>
        <v-chip class="ma-2"
                style="margin-top: -15px !important;">
            Price List
        </v-chip>
        <v-btn color="green"
               dark
               small
               absolute
               top
               right
               fab
               @click="createPriceListRow()">
            <v-icon size="medium">fas fa-plus</v-icon>
        </v-btn>

        <v-container fluid
                     grid-list-lg>

            <PriceListRow v-for="row in priceList" :key="row.id" 
                          v-bind:id="row.id" v-on:remove="removeRow($event)" 
                          v-on:updateItem="updateRow($event)">
            </PriceListRow>

        </v-container>
    </v-card>
</template>

<script>
    import { globalFunctions } from '../../javascript/globalFunctions'

    export default {
        components: {
            PriceListRow: () => import('./itemsPriceListRow.vue')
        },
        data() {
            return {
                priceList: [],
                pricelistRow: {
                    id: 0,
                    itemPrice: 0,
                    quantity: 1
                }
            }
        },
        methods: {
            createPriceListRow: function () {
                this.pricelistRow.id = globalFunctions.getNextIdForObject(this.priceList)

                this.priceList.push(globalFunctions.stringify(this.pricelistRow))
            },
            removeRow: function (id) {
                for (let i = 0; i < this.priceList.length; i++) {
                    if (this.priceList[i].id === id) {
                        this.priceList.splice(i, 1)
                        break;
                    }
                }
            },
            updateRow: function (row) {
                for (let i = 0; i < this.priceList.length; i++) {
                    if (this.priceList[i].id === row.id) {
                        this.priceList[i].itemPrice = row.itemPrice
                        this.priceList[i].quantity = row.quantity

                        break;
                    }
                }
                this.returnPriceList()
            },
            returnPriceList: function () {
                this.$emit('priceList', this.priceList)
            }

        },
        beforeMount() {
            this.createPriceListRow()
        }
    }

</script>